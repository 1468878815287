"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notification = void 0;
const func_1 = require("../../assest/func");
var notification;
(function (notification) {
    notification.create = () => {
        // body
        //     --side
        //     --text
        //         -- header
        //         -- value
        const body = (0, func_1.createElFromDesc)({ tag: 'div', id: 'mpts-notification' });
        const side = (0, func_1.createElFromDesc)({ tag: 'div', id: 'mpts-notification-side' });
        const text = (0, func_1.createElFromDesc)({ tag: 'div', id: 'mpts-notification-text' });
        const header = (0, func_1.createElFromDesc)({ tag: 'div', id: 'mpts-notification-header', textContent: 'Alarm it`s HeAdEr' });
        const valueText = 'Lorem ipsum (/ˌlɔː.rəm ˈɪp.səm/ LOR-əm IP-səm) is a dummy or placeholder text commonly used in graphic design, publishing, and web development to fill empty spaces in a layout that does not yet have content.';
        const value = (0, func_1.createElFromDesc)({ tag: 'div', id: 'mpts-notification-value', textContent: valueText });
        text.appendChild(header);
        text.appendChild(value);
        body.appendChild(side);
        body.appendChild(text);
        const parent = document.getElementById('ui-layer');
        // parent.appendChild(body);
        document.body.appendChild(body);
        // Создает элемент уведомления
        const createNotificationElement = (options) => {
            const { message, type } = options;
            // const {
            //     tag,
            //     classList = [],
            //     attributes = {},
            //     textContent,
            //     children = [],
            //     data
            // } = descriptor;
            // const icon = document.createElement('span');
            // icon.style.marginRight = '10px';
            // icon.style.fontSize = '16px';
            // switch (type) {
            //     case 'error':
            //         icon.textContent = '⚠️';
            //         break;
            //     case 'notification':
            //         icon.textContent = 'ℹ️';
            //         break;
            //     case 'info':
            //         icon.textContent = '🛈';
            //         break;
            // }
            // const closeButton = document.createElement('span');
            // closeButton.textContent = '×';
            // closeButton.style.marginLeft = '10px';
            // closeButton.style.cursor = 'pointer';
            // closeButton.addEventListener('click', () => body.remove());
            // body.appendChild(icon);
            // const messageText = document.createElement('span');
            // messageText.textContent = message;
            // body.appendChild(messageText);
            // if (type === 'error') {
            //     body.appendChild(closeButton);
            // }
            // Стили для разных типов уведомлений
            // switch (type) {
            //     case 'error':
            //         body.style.backgroundColor = '#f44336';
            //         body.style.color = '#fff';
            //         break;
            //     case 'notification':
            //         body.style.backgroundColor = '#333';
            //         body.style.color = '#fff';
            //         break;
            //     case 'info':
            //         body.style.backgroundColor = '#2196F3';
            //         body.style.color = '#fff';
            //         break;
            // }
            return body;
        };
        // // Показать уведомление
        // const showNotification = (options: NotificationOptions): void => {
        //     const { type, duration } = options;
        //     const notification = createNotificationElement(options);
        //     document.body.appendChild(notification);
        //     if (type !== 'error' && duration) {
        //         setTimeout(() => {
        //             notification.remove();
        //         }, duration);
        //     }
        // };
        // // Пример использования
        // const buttonError = document.createElement('button');
        // buttonError.textContent = 'Показать ошибку';
        // buttonError.style.padding = '10px 20px';
        // buttonError.style.margin = '20px';
        // buttonError.style.fontSize = '16px';
        // buttonError.addEventListener('click', () => {
        //     showNotification({ message: 'Это ошибка!', type: 'error' });
        // });
        // document.body.appendChild(buttonError);
        // const buttonNotification = document.createElement('button');
        // buttonNotification.textContent = 'Показать уведомление';
        // buttonNotification.style.padding = '10px 20px';
        // buttonNotification.style.margin = '20px';
        // buttonNotification.style.fontSize = '16px';
        // buttonNotification.addEventListener('click', () => {
        //     showNotification({ message: 'Это уведомление!', type: 'notification', duration: 3000 });
        // });
        // document.body.appendChild(buttonNotification);
        // const buttonInfo = document.createElement('button');
        // buttonInfo.textContent = 'Показать информацию';
        // buttonInfo.style.padding = '10px 20px';
        // buttonInfo.style.margin = '20px';
        // buttonInfo.style.fontSize = '16px';
        // buttonInfo.addEventListener('click', () => {
        //     showNotification({ message: 'Это информация!', type: 'info', duration: 3000 });
        // });
    };
})(notification || (exports.notification = notification = {}));
