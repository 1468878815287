"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.send = exports.MessageTypes = void 0;
const login_1 = require("../site_components/login/login");
const app_1 = require("./app");
const socket = io();
var MessageTypes;
(function (MessageTypes) {
    MessageTypes["userRequest"] = "";
    MessageTypes["demo"] = "demo";
    MessageTypes["get_users"] = "get_users";
    MessageTypes["login"] = "login";
    MessageTypes["chech_otp"] = "chech_otp";
    MessageTypes["getId"] = "getId";
    MessageTypes["getName"] = "getName";
    MessageTypes["setName"] = "setName";
})(MessageTypes || (exports.MessageTypes = MessageTypes = {}));
const send = (_m, _p) => {
    try {
        switch (_m) {
            case MessageTypes.setName:
                socket.emit(_m.toString(), _p, (resp) => { app_1.app.getApp().ui.header.setUserName(resp); });
                break;
            case MessageTypes.getName:
                socket.emit(_m.toString(), (resp) => { _p.value = resp; });
                break;
            case MessageTypes.getId:
                socket.emit(_m.toString());
                break;
            case MessageTypes.demo:
                socket.emit(_m);
                break;
            case MessageTypes.get_users:
                socket.emit(_m.toString());
                break;
            case MessageTypes.login:
                socket.emit(_m, _p);
                break;
            case MessageTypes.chech_otp:
                socket.emit(_m, _p);
                break;
            default:
                console.warn(`Unhandled message type: ${_m}`);
                break;
        }
    }
    catch (error) {
        console.error(`Error sending message: ${error}`);
    }
};
exports.send = send;
socket.on('it_is_users_list', (val) => {
    console.log(val);
});
socket.on('user_exists', (val) => {
    console.log('User exists:', val);
});
socket.on('otp_request', (val) => {
    if (val.ft) {
        // it is first time login
        console.log('OTP requested');
        login_1.login.switchLoginState(login_1.login.loginStates.otp);
        login_1.login.setNotinotifier().info('Please check your email for OTP setup');
        console.log('Registred first time', val.v);
    }
    else {
        console.log('OTP requested');
        login_1.login.switchLoginState(login_1.login.loginStates.otp);
        console.log('Registred', val.v);
    }
});
socket.on('login_success', (val) => {
    console.log('User logged in:', val);
    login_1.login.switchLoginState(login_1.login.loginStates.user, val.name);
});
socket.on('wrong_password', (val) => {
    console.log('Wrong password for', val);
    login_1.login.setNotinotifier().info('Wrong password');
    login_1.login.switchLoginState(login_1.login.loginStates.login, val);
});
socket.on('wrong_otp', (val) => {
    console.log('Wrong OTP:', val);
    login_1.login.switchLoginState(login_1.login.loginStates.otp);
    login_1.login.clearOTPInputs();
    login_1.login.setNotinotifier().error('Wrong OTP');
});
socket.on('takeId', (v) => {
    const uint8Array = new Uint8Array(v);
    const hexString = Array.from(uint8Array)
        .map(byte => byte
        .toString(16)
        .padStart(2, '0'))
        .join('');
    console.log(hexString);
});
