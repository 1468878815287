"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const header_1 = require("../site_components/header/header");
const notification_1 = require("../site_components/notification/notification");
console.log('mpts', crypto.randomUUID(), `${new Date().toISOString()}`);
const _dd = performance.now();
const run = () => {
    header_1.header.create();
    // footer
    // home.create()
    // login.initialize()
    // send(MessageTypes.get_users)
};
run();
notification_1.notification.create();
console.log('build in ', performance.now() - _dd);
