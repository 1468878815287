"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkPassword = exports.checkEmail = exports.getHash = void 0;
/**
 * Generates a hash of the input string using specified algorithm
 * @param _s - Input string to hash
 * @param _alg - Hash algorithm to use (defaults to SHA-256)
 * @throws Error if invalid algorithm specified or hashing fails
 * @returns Promise<string> - Hexadecimal hash string
 */
const getHash = (_s_1, ...args_1) => __awaiter(void 0, [_s_1, ...args_1], void 0, function* (_s, _alg = 'SHA-256') {
    // Input validation
    if (!_s) {
        throw new Error('Input string cannot be empty');
    }
    const supportedAlgorithms = ['SHA-1', 'SHA-256', 'SHA-384', 'SHA-512'];
    if (!supportedAlgorithms.includes(_alg)) {
        throw new Error(`Unsupported algorithm: ${_alg}. Use one of: ${supportedAlgorithms.join(', ')}`);
    }
    try {
        const utf8 = new TextEncoder().encode(_s);
        const hashBuffer = yield crypto.subtle.digest(_alg, utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    }
    catch (error) {
        throw new Error(`Failed to generate hash: ${error.message}`);
    }
});
exports.getHash = getHash;
/**
 * Validates email address
 * Requirements:
 * - Maximum length 254 characters
 * - Valid email format according to RFC 5322
 * - Supports IDN (International Domain Names)
 * - Supports IP literal addresses
 * @param _email - Email address to validate
 * @returns EmailValidationResult - Validation result with potential errors
 */
const checkEmail = (_email) => {
    const errors = [];
    if (!_email) {
        errors.push('Email address cannot be empty');
        return { isValid: false, errors };
    }
    if (_email.length > 254) {
        errors.push('Email address cannot be longer than 254 characters');
        return { isValid: false, errors };
    }
    try {
        const normalizedEmail = _email.split('@').map(part => {
            try {
                return part.includes('xn--') || /[^\x00-\x7F]/.test(part)
                    ? new URL('http://' + part).hostname
                    : part;
            }
            catch (_a) {
                return part;
            }
        }).join('@');
        const emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        if (!emailPattern.test(normalizedEmail)) {
            errors.push('Invalid email format. Please check if email address is correct');
        }
    }
    catch (error) {
        errors.push('Error processing email address: ' + error.message);
    }
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkEmail = checkEmail;
/**
 * Validates password strength
 * Requirements:
 * - Minimum length 12 characters
 * - Contains at least 2 digits
 * - Contains at least 2 uppercase letters
 * - Contains at least 2 special characters
 */
const checkPassword = (_password) => {
    const errors = [];
    const validations = [
        {
            test: (pwd) => pwd.length >= 12 && pwd.length <= 128,
            message: 'Password must be between 12 and 128 characters'
        },
        {
            test: (pwd) => (pwd.match(/[0-9]/g) || []).length >= 2,
            message: 'Password must contain at least 2 digits'
        },
        {
            test: (pwd) => (pwd.match(/[A-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 uppercase letters'
        },
        {
            test: (pwd) => (pwd.match(/[^0-9a-zA-Z]/g) || []).length >= 2,
            message: 'Password must contain at least 2 special characters'
        }
    ];
    validations.forEach(validation => {
        if (!validation.test(_password)) {
            errors.push(validation.message);
        }
    });
    return {
        isValid: errors.length === 0,
        errors
    };
};
exports.checkPassword = checkPassword;
