"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tr_1 = require("../core/tr");
const app_1 = require("./app");
console.log('mpts', crypto.randomUUID(), `${new Date().toISOString()}`);
const _dd = performance.now();
const run = () => {
    const _app = app_1.app.getApp(tr_1.lang.eng);
    setTimeout(() => {
        // _app.translate(lang.rus)
        // _app.ui.header.foldLogo()
    }, 2000);
    // header.create()
    // footer
    // home.create()
    // login.initialize()
    // send(MessageTypes.get_users)
};
run();
// notification.create()
console.log('build in ', performance.now() - _dd);
