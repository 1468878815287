"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = void 0;
// Code Created with love to my son, and my wife, and my mother
const tr_1 = require("../core/tr");
const header_1 = require("../site_components/header/header");
/** core front app item */
var app;
(function (app_1) {
    let app = null;
    app_1.getApp = (l = tr_1.lang.eng) => {
        return app ? app : create(l);
    };
    const create = (l) => {
        const _header = header_1.header.create(l);
        app = {
            id: crypto.randomUUID(),
            lang: l,
            translate: _translate,
            ui: {
                header: _header
            }
        };
        return app;
    };
    const _translate = (newLang) => {
        app.lang = newLang;
        app.ui.header.translate(newLang);
    };
})(app || (exports.app = app = {}));
