"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
const tr_1 = require("../../core/tr");
var home;
(function (home) {
    home.instance = () => {
        let _l = document.getElementById('mpts-home');
        const switchVisibility = () => {
            if (_l.style.display == 'none') {
                _l.style.display = 'block';
            }
            else {
                _l.style.display = 'none';
            }
        };
        return {
            item: _l,
            hide: switchVisibility(),
            show: switchVisibility()
        };
    };
    home.create = (parent = 'ui-layer', l = tr_1.lang.eng) => {
        fetch("pages/home.html")
            .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.text();
        })
            .then((data) => {
            document.getElementById(parent).innerHTML = data.toString();
        })
            .catch((error) => {
            console.error("Fetch error:", error);
            document.getElementById(parent).innerHTML = "<p>Fetch error.</p>";
        });
        // ml_items_panel.create('mpts-home')
    };
})(home || (exports.home = home = {}));
