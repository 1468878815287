"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.login = void 0;
const tr_1 = require("../../core/tr");
const func_1 = require("../../scripts/func");
const transport_1 = require("../../scripts/transport");
const header_1 = require("../header/header");
var login;
(function (login_1) {
    let loginStates;
    (function (loginStates) {
        loginStates["login"] = "login";
        loginStates["otp"] = "otp";
        loginStates["user"] = "user";
    })(loginStates = login_1.loginStates || (login_1.loginStates = {}));
    let _emmail = '';
    /** switch views */
    login_1.switchLoginState = (state, val) => {
        const _elements = {
            login: document.getElementById('direct-login'),
            otp: document.getElementById('otp-login'),
            user: document.getElementById('user-view'),
        };
        _emmail = val;
        console.log();
        const instr = document.getElementById('user-login-instruction');
        // Validate that all elements exist
        Object.entries(_elements).forEach(([key, element]) => {
            if (!element)
                throw new Error(`Required element ${key} not found`);
        });
        // Hide all elements
        Object.values(_elements).forEach(element => {
            element.style.display = 'none';
        });
        // Show the selected state
        switch (state) {
            case loginStates.login:
                _elements.login.style.display = 'grid';
                instr.style.display = 'flex';
                break;
            case loginStates.otp:
                _elements.otp.style.display = 'flex';
                instr.style.display = 'flex';
                login_1.getOTPInputs()[0].blur();
                login_1.getOTPInputs()[0].focus();
                break;
            case loginStates.user:
                _elements.user.style.display = 'flex';
                _elements.user.style.flexDirection = 'column';
                instr.style.display = 'none';
                header_1.header.setUserName(`@${val}`);
                login_1.setNotinotifier().hide();
                const ni = document.getElementById('user-name-input');
                ni.value = val;
                document.getElementById('user-name-update-button').addEventListener('pointerdown', () => {
                    (0, transport_1.send)(transport_1.MessageTypes.getName, ni);
                    (0, transport_1.send)(transport_1.MessageTypes.getId);
                });
                document.getElementById('user-name-change-button').addEventListener('pointerdown', () => {
                    (0, transport_1.send)(transport_1.MessageTypes.setName, ni.value);
                });
                break;
            default:
                throw new Error(`Unknown login state: ${state}`);
        }
    };
    login_1.setName = () => {
    };
    login_1.instance = () => {
        const _l = document.getElementById('content-signin');
        if (!_l)
            throw new Error('Login element not found');
        const switchVisibility = () => {
            _l.style.display = _l.style.display === 'none' ? 'block' : 'none';
        };
        return {
            item: _l,
            hide: () => switchVisibility(),
            show: () => switchVisibility()
        };
    };
    login_1.setNotinotifier = (el) => {
        if (!el)
            el = document.getElementById('login-notifier');
        return {
            hide: () => {
                el.style.display = 'none';
            },
            error: (message) => {
                el.style.display = 'block';
                el.innerText = message;
                el.className = 'error-message';
            },
            info: (message) => {
                el.style.display = 'block';
                el.innerText = message;
                el.className = 'info-message';
            }
        };
    };
    const setCheckers = () => {
        const elements = {
            notifier: document.getElementById('login-notifier'),
            emailInput: document.getElementById('mpt-email'),
            passwordInput: document.getElementById('mpt-password'),
        };
        // Validate that all elements exist
        Object.entries(elements).forEach(([key, element]) => {
            if (!element)
                throw new Error(`Required element ${key} not found`);
        });
        const debounce = (func, wait) => {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func(...args), wait);
            };
        };
        const handleEmailValidation = debounce(() => {
            const result = (0, func_1.checkEmail)(elements.emailInput.value);
            if (!result.isValid) {
                login_1.setNotinotifier(elements.notifier).error(result.errors[0] || 'Invalid email format');
                // notifier.error(result.errors[0] || 'Invalid email format');
            }
            else {
                login_1.setNotinotifier(elements.notifier).hide();
                // notifier.hide();
            }
        }, 1);
        const handlePasswordValidation = debounce(() => {
            const result = (0, func_1.checkPassword)(elements.passwordInput.value);
            if (!result.isValid) {
                login_1.setNotinotifier(elements.notifier).error(result.errors.join('\n'));
                // notifier.error(result.errors.join('\n'));
            }
            else {
                login_1.setNotinotifier(elements.notifier).hide();
                // notifier.hide();
            }
        }, 1);
        elements.emailInput.addEventListener('input', handleEmailValidation);
        elements.passwordInput.addEventListener('input', handlePasswordValidation);
        return {
            cleanup: () => {
                elements.emailInput.removeEventListener('input', handleEmailValidation);
                elements.passwordInput.removeEventListener('input', handlePasswordValidation);
            }
        };
    };
    const setSubmit = () => {
        const elements = {
            emailInput: document.getElementById('mpt-email'),
            passwordInput: document.getElementById('mpt-password'),
            submitButton: document.getElementById('mpt-submit-user'),
            notifier: document.getElementById('login-notifier'),
        };
        // Validate that all elements exist
        Object.entries(elements).forEach(([key, element]) => {
            if (!element)
                throw new Error(`Required element ${key} not found`);
        });
        elements.submitButton.addEventListener('click', () => {
            const email = elements.emailInput.value;
            const password = elements.passwordInput.value;
            const emailValidation = (0, func_1.checkEmail)(email);
            if (!emailValidation.isValid) {
                login_1.setNotinotifier(elements.notifier).error('Invalid email format');
                return;
            }
            const passwordValidation = (0, func_1.checkPassword)(password);
            if (!passwordValidation.isValid) {
                login_1.setNotinotifier(elements.notifier).error('Invalid password format');
                return;
            }
            document.getElementById('otp-login-header').textContent = document.getElementById('mpt-email').value;
            // Send login request
            try {
                (0, func_1.getHash)(`${email}${password}`).then((hash) => {
                    console.log('Hash:', hash);
                    (0, transport_1.send)(transport_1.MessageTypes.login, { e: email, h: hash });
                });
            }
            catch (error) {
                console.error('Login error:', error);
            }
        });
    };
    login_1.getOTPInputs = () => {
        return document.querySelectorAll("#otp-login-inputs input");
    };
    login_1.clearOTPInputs = () => {
        const inputs = login_1.getOTPInputs();
        inputs.forEach((input) => { input.value = ""; input.classList.remove("disabled"); });
        inputs[0].focus();
    };
    const otpHandler = () => {
        const inputs = login_1.getOTPInputs();
        inputs[0].focus();
        // document.getElementById('otp-login-header').textContent = (document.getElementById('mpt-email') as HTMLInputElement).value
        inputs.forEach((input) => {
            input.addEventListener("keyup", handleOtp);
            input.addEventListener("paste", handleOnPasteOtp);
        });
        function handleOtp(e) {
            const input = e.target;
            let value = input.value;
            let isValidInput = value.match(/[0-9]/gi);
            input.value = "";
            input.value = isValidInput ? value[0] : "";
            let fieldIndex = Number(input.getAttribute('data-index'));
            if (fieldIndex < inputs.length - 1 && isValidInput) {
                input.nextElementSibling.focus();
            }
            if (e.key === "Backspace" && fieldIndex > 0) {
                input.previousElementSibling.focus();
            }
            if (fieldIndex == inputs.length - 1 && isValidInput) {
                submit();
            }
        }
        function handleOnPasteOtp(e) {
            const data = e.clipboardData.getData("text");
            const value = data.split("");
            if (value.length === inputs.length) {
                inputs.forEach((input, index) => (input.value = value[index]));
                submit();
            }
        }
        function submit() {
            console.log("Submitting...");
            // 👇 Entered OTP
            let otp = "";
            inputs.forEach((input) => { otp += input.value; });
            //? chech otp
            otp.length === 6 ? inputs.forEach((input) => { input.classList.add("disabled"); input.blur(); }) : 0;
            console.log(otp);
            // 👉 Call API below
            (0, transport_1.send)(transport_1.MessageTypes.chech_otp, otp);
            // socket.emit('verify_otp', otp)
        }
    };
    login_1.create = (...args_1) => __awaiter(this, [...args_1], void 0, function* (parent = 'ui-layer', l = tr_1.lang.eng) {
        const parentElement = document.getElementById(parent);
        if (!parentElement)
            throw new Error(`Parent element ${parent} not found`);
        try {
            const response = yield fetch("pages/login.html");
            if (!response.ok)
                throw new Error("Network response was not ok");
            const data = yield response.text();
            parentElement.innerHTML = data;
            /** waiting for DOM */
            setTimeout(() => {
                try {
                    login_1.switchLoginState(loginStates.login);
                    // switchLoginState(loginStates.user);
                    setCheckers();
                    setSubmit();
                    otpHandler();
                }
                catch (error) {
                    console.error("Validation setup error:", error);
                }
            }, 0);
        }
        catch (error) {
            console.error("Fetch error:", error);
            parentElement.innerHTML = "<p>Fetch error.</p>";
        }
    });
    // ---------------------------------------------------- WORK WITH JWT
    let accessToken = null;
    function initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('Initializing application...');
            if (!accessToken) {
                try {
                    const response = yield fetch('auth/initialize', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            value: '1'
                        }),
                        credentials: 'include',
                    });
                    console.log(response.status, response.statusText);
                    if (response.status === 200) {
                        const data = yield response.json();
                        accessToken = data.accessToken;
                        storeAccesTokenLocaly(accessToken);
                        console.log('Access Token initialized:', accessToken);
                    }
                    else {
                        console.log('Initialization failed. Please log in again.');
                        login();
                    }
                }
                catch (err) {
                    console.log('Error during initialization:', err);
                }
            }
            else {
                console.log('Access Token already available:', accessToken);
            }
        });
    }
    login_1.initialize = initialize;
    function storeAccesTokenLocaly(token) {
        return __awaiter(this, void 0, void 0, function* () {
            window.localStorage.setItem('accesToken', token);
        });
    }
    login_1.storeAccesTokenLocaly = storeAccesTokenLocaly;
    function login() {
        return __awaiter(this, arguments, void 0, function* (username = 'username', password = '1') {
            try {
                const response = yield fetch('auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ username, password }),
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = yield response.json();
                    accessToken = data.accessToken;
                    storeAccesTokenLocaly(accessToken);
                    console.log('Login successful. Access Token:', accessToken);
                }
                else {
                    console.error('Login failed:', response.statusText);
                }
            }
            catch (err) {
                console.error('Error during login:', err);
            }
        });
    }
    login_1.login = login;
    function logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch('auth/logout', {
                    method: 'POST',
                    credentials: 'include'
                });
                if (response.ok) {
                    console.log('Logged out successfully');
                }
                else {
                    console.error('Failed to log out:', response.statusText);
                }
            }
            catch (err) {
                console.error('Error during logout:', err);
            }
        });
    }
    login_1.logout = logout;
    // ---------------------------------------------------- ENC DEC 
    /**
     *
     * @param v value to encrypt
     * @param k key
     */
    function hidenEnc(v, k) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                encryptWithPemPublicKey(v, k).then((_v) => __awaiter(this, void 0, void 0, function* () {
                    const response = yield fetch('auth/calc', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            value: _v
                        }),
                    });
                    if (response.ok) {
                        console.log('enc : ');
                    }
                    else {
                        console.error('enc : ', response.statusText);
                    }
                }));
            }
            catch (err) {
                console.error('enc : ', err);
            }
        });
    }
    login_1.hidenEnc = hidenEnc;
    function importPemPublicKey(pemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const base64Key = pemKey
                .replace(/-----BEGIN PUBLIC KEY-----/, "")
                .replace(/-----END PUBLIC KEY-----/, "")
                .replace(/[\n\r]/g, "")
                .trim();
            const binaryKey = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0)).buffer;
            return yield window.crypto.subtle.importKey("spki", binaryKey, {
                name: "RSA-OAEP",
                hash: "SHA-256",
            }, true, ["encrypt"]);
        });
    }
    function encryptWithPemPublicKey(message, pemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const publicKey = yield importPemPublicKey(pemKey);
            const encodedMessage = new TextEncoder().encode(message);
            const encrypted = yield window.crypto.subtle.encrypt({
                name: "RSA-OAEP",
            }, publicKey, encodedMessage);
            return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
        });
    }
    function bufferToHex(buffer) {
        return Array.from(new Uint8Array(buffer))
            .map(b => b.toString(16).padStart(2, "0"))
            .join("");
    }
})(login || (exports.login = login = {}));
