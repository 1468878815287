"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.header = void 0;
// import { app } from "../../../backend/mics/objects/app";
const home_1 = require("../home/home");
const login_1 = require("../login/login");
var header;
(function (header) {
    const _items = document.querySelectorAll('.header-item');
    // const _app = app.getApp()
    header.create = () => {
        _items.forEach(el => {
            el.addEventListener('pointerdown', () => {
                if (el.classList.contains("disabled")) {
                    return;
                }
                _items.forEach(el => el.classList.remove('active'));
                el.classList.add("active");
                // manage content by click
                switch (el.id) {
                    case 'mtps_signin':
                        // if (_app.user.props.id !== '') {
                        //     login.switchLoginState(login.loginStates.user)
                        // } else {
                        login_1.login.create();
                        // }
                        break;
                    case 'mpts_home_button':
                        home_1.home.create();
                        break;
                    default:
                        break;
                }
            });
        });
    };
    header.setUserName = (name) => {
        const _name = document.getElementById('mtps_signin');
        _name.innerText = name;
        _name.classList.remove('highlited');
        _name.classList.add('userlogged');
        document.getElementById('mpts_app_button').classList.remove('disabled');
    };
    const minimazeLogo = () => {
        const logo = document.getElementById('mpts-logo');
        if (logo.classList.contains('state1')) {
            logo.style.letterSpacing = '-4px';
            logo.style.paddingRight = '0px';
            document.getElementById('mpts-header-items').style.paddingLeft = '16px';
            logo.classList.remove('state1');
            logo.classList.add('state2');
            logo.innerHTML = 'M | <span>t</span>';
        }
        else {
            logo.style.letterSpacing = 'normal';
            logo.classList.remove('state2');
            logo.classList.add('state1');
            logo.innerHTML = 'Metaprod | <span>trace</span>';
        }
    };
    /** handle APP button click */
    document.getElementById('mpts_app_button').addEventListener('pointerdown', () => {
        _items.forEach(el => {
            el.id !== 'mtps_signin' ? el.style.display = 'none' : 0;
        });
        minimazeLogo();
    });
})(header || (exports.header = header = {}));
